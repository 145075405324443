.toolkit-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.toolkit-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toolkit-title {
    color: #222222;
    text-align: center;
}

.toolkit-row {
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 930px) {
    .toolkit-row{
        display: block;
    }
}

