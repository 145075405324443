/* Profile.css */
.profile-container {
    width: 100%;
    min-height: 100vh;
}

.profile-box{
    display: grid;
    grid-template-columns: 1fr 1fr; /* Split into two columns */
    grid-gap: 5px; /* Add spacing between columns */
    margin: 20px 2%;
}

.profile-container-aligned-left,
.profile-container-aligned-right {
    grid-column: span 1; /* Occupy one column */
    grid-row: span 10;
    padding: 10px; /* Add padding to the left and right */
}

.profile-container-aligned-top {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    grid-column: span 2; /* Occupy two columns (full width) */
    grid-row: span 1;

    /* Add any other styles you want */
}

/* Add margin to the buttons */
.profile-container-aligned-top button {
}




/* Profile Title */
.profile-title {
    font-size: 24px;
    margin-bottom: 0px;
}

/* Profile Subtitle */
.profile-sub-title {
    font-size: 16px;
    color: #777;
    margin-bottom: 40px;
}

/* Profile Buttons Container */
.profile-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Profile Button */
.profile-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #666666; /* Dark Gray */
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.profile-button:hover {
    background-color: #444444; /* Darker Gray on Hover */
}
/* Profile New Request Button */
.profile-new-request-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #ff8000; /* Orange */
    color: #222222;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.profile-new-request-button:hover {
    background-color: #e68a00; /* Darker Orange on Hover */
}

/* My Appointments Button */
.profile-appointments-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #666666; /* Dark Gray */
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.profile-appointments-button:hover {
    background-color: #444444; /* Darker Gray on Hover */
}
/* Profile Admin Button */
.profile-admin-button {
    background-color: #222222;
    color: #ff8000;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    font-weight: bold;
}

.profile-admin-button:hover {
    background-color: #222222;
    color: #ffffff;
}


@media (max-width: 930px) {
    .profile-box{
        display: block;
    }
}