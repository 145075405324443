/* Register.css */

.register-form-container {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 100vh;
    width: 100vw;
    margin-top: 5%;
}
.register-page-title {
    background-color: #444444;
    text-align: center;
    padding: 5px;
    color: #ff6600;
    margin-top: 5%;
    border-radius: 5px;
}
.register-form-section {
    background-color: whitesmoke;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
}

.register-form-section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.register-input-field {
    margin-bottom: 15px;
    margin-right: 15px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="checkbox"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.password-requirements ul {
    padding: 0;
}

.password-requirements {
    font-size: 16px;
}
.password-requirements h1 {
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.register-submit-button {
    background-color: #444444;
    color: #222222;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}
.register-submit-button-comment {
    background-color: #444444;
    color: #ff6600;
    text-decoration: underline;
    border: none;
    padding: 10px;
    border-radius: 2px;

}


.register-next-button {
    background-color: #ff6600;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.register-next-icon {
    font-size: 40px;
}

.register-submit-icon {
    font-size: 80px;
}



.term-page-new-tab span{
    text-decoration: underline;
    color: #ff6600;
    cursor: pointer;
}
