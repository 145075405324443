/* ProfileSettings.css */

.profile-settings-container {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;

    min-height: 100vh;
}

.profile-settings-title {
    color: #444444;
}
.profile-settings-paragraph-title {
    display: block;
    margin-right: 30px;
    font-weight: bold;
    color: black;
}

.form-group {
    margin-bottom: 10px;
}







.profile-settings-button-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
}


.single-button-container {
    background-color: #444444;
    font-size: 16px;
    color: #ff8000;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
}


.profile-settings-cancel-button {
    background-color: #444444;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
}

.profile-settings-submit-button {
    background-color: #444444;
    font-size: 16px;
    color: #ff8000;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
}

.single-button-container:hover {
    background-color: #444444;
    /*color: #ff8000;*/
}

.profile-settings-cancel-button:hover {
    background-color: #444444;
    /*color: #ff8000;*/
}

.profile-settings-submit-button:hover {
    background-color: #444444;
    /*color: #ff8000;*/
}

.profile-setting-paragraph-container{
    min-width: 300px;
    padding: 5px;
}


.profile-setting-paragraph-container-no-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    margin-top: 10px;
}


.profile-settings-paragraph-value-no-edit {
    text-align: left;
    min-width: 150px;
    margin-left: 40px;
}

.box{
    background-color: #f5f5f5;
    color: black;
    border-radius: 5px;
}


.no-edit-box{

}

edit-box{

}

