/* Container Styles */
.demo-paybackperiod-container {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Title Styles */
.demo-title {
    font-size: 24px;
    color: #FF8000;
    margin: 0;
}

/* Subtitle Styles */
.demo-subtitle {
    font-size: 18px;
    color: #333;
    margin: 10px 0;
}

/* Form Styles */
.demo-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Input Field Styles */
.demo-input-field {
    margin: 10px 0;
    text-align: left;
}

.demo-input-field label {
    font-size: 14px;
    color: #555;
    display: block;
    margin-bottom: 5px;
}

.demo-input-field input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
}

/* Button Styles */
.demo-button-container {
    margin-top: 20px;
}

.demo-primary-button {
    background-color: #FF8000;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.demo-secondary-button {
    background-color: #555;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
