/* Footer.css */

.footer {
    background-color: #333333;
    color: #f5f5f5;
    padding-bottom: 1px;
}

.footer-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-logo img {
    width: 50px;
    height: auto;
}


.footer-logo-text {
    color: #f5f5f5;
    font-weight: bold;
}

.footer-links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.footer-nav-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.footer-nav-link {
    text-decoration: none;
    color: #f5f5f5;
    margin: 0 15px;
    transition: color 0.3s;

}

.footer-nav-link:hover {
    color: #ff8000;
}

.footer-nav-link.active-tab:hover {
    color: #f5f5f5;
}

.footer-bottom {
    text-align: center;
}

.footer-bottom .copyright {
    bottom: 0;
    font-size: 0.8rem;
    color: #f5f5f5;
}

.footer-toa-pp-disc {
    display: flex;
    justify-content: center;
}


@media (max-width: 1000px) {
    .footer{
        width: 100%;
    }

    .footer-logo-text{
        font-size: 2rem;
    }

    .footer-links{
        font-size: 0.7rem;
    }

    .footer-toa-pp-disc{
        font-size: 0.7rem;
    }
}

.footer-home-link-container {
    text-decoration: none;
}
