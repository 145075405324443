/* RequestsAdmin.css */

.admin-pricequotes-container {
}

.admin-pricequotes-title {
    text-align: center;
}

.admin-pricequotes-ul {
    list-style-type: none;
}

.admin-pricequote-item {
    background-color: #fff;
}

.admin-pricequote-id,
.admin-pricequote-flat-roof,
.admin-pricequote-slanted-roof,
.admin-pricequote-solar-panel,
.admin-pricequote-total-cost {
    margin: 0;
}

.admin-pricequote-bar-title-alignment {
    display: flex; /* Make it a flex container */
    flex-direction: row;
    justify-content: space-evenly;

}