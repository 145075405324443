/* contact.css */

.contact-container {
    margin: 0 auto;
    padding: 4% 10%;
    border-radius: 5px;
    background-color: #f5f5f5;

    min-height: 100vh;
    max-width: 100%;
}

.contact-title {
    margin: 0 0 20px;
    font-size: 24px;
    text-align: center;
}

.contact-paragraph {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.5;
}

.contact-paragraph a {
    color: #ff8000;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-paragraph a:hover {
    text-decoration: underline;
    color: darkorange;
}

.contact-appointment-title {
    text-align: center;
}

.form-control {
    margin-bottom: 15px;
}

.radio-group {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
}

.radio-group label {
    font-size: 16px;
    cursor: pointer;
}

.text-field {
    margin-bottom: 25px; /* Changed from 15px to provide more spacing */
}

.submit-appointment-button {
    margin-top: 15px;
    background-color: #ff8000;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, opacity 0.2s ease;
}

.submit-appointment-button:hover {
    background-color: #f57c00;
    transform: scale(1.05);
    opacity: 0.9;
    animation: pulse 1.5s infinite;
}

/* Additional styles for form fields */
.text-field label {
    font-weight: bold;
}

/* Additional styles for links */
.contact-paragraph a {
    text-decoration: underline;
    transition: color 0.3s ease;
}

.contact-paragraph a:hover {
    color: darkorange;
}

/* Styles for the contact-mail-and-appointment-widget-container */
.contact-mail-and-appointment-widget-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 50px;
}

/* Styles for contact-mail-aligned-left */
.contact-mail-aligned-left {
    flex: 1;
}

.make-box-around-text{
    background-color: #f8f8f8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow for a lifted effect */

    padding: 5px;
    border-radius: 5px;
}

/* Styles for contact-mail-aligned-right */
.contact-mail-aligned-right {
    flex: 1;
    background-color: #f5f5f5;
    max-width: 400px;
}

.contact-appointment-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.contact-appointment-title-align-start {
    flex: 1;
    padding: 20px;
}
.contact-appointment-title-align-end {
    flex: 1;
    padding: 20px;
}


/*
Do you like this styling?

!*!* contact.css *!

.contact-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin: 20px;
}

.contact-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-mail-and-appointment-widget-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.contact-mail-aligned-left {
    flex: 1;
}

.contact-paragraph {
    margin-bottom: 10px;
}

.contact-details {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.contact-mail-aligned-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.contact-appointment-title {
    font-size: 20px;
    margin-top: 20px;
}

.contact-appointment-title a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.contact-paragraph a {
    color: #ff8000;
    text-decoration: none;
    font-weight: bold;
}*!*/
@media (min-width: 930px) {
    .contact-appointment-title-align-end{
        margin-left: 40px;
    }
}

@media (max-width: 930px) {

    .contact-container{
        padding: 20px 5%;
    }

    .contact-appointment-title-row{
        flex-direction: column;
    }

    .contact-mail-and-appointment-widget-container{
        flex-direction: column;
    }

    .contact-appointment-title-align-end{
        margin-top: 20px;
    }
}
