
.article-card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 25px;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid #444444;
    background-color: #ffffff;
}

.article-card-container img{
    width: 250px;
    height: 150px;
    border-radius: 10px;
    margin-right: 20px;
}

.article-card-container h2{
    font-size: 1.5rem;
    text-decoration: none;
    color: black;
}


.article-image{
    width: 800px;
    height: 500px;
}

.article-image img{
    width: 800px;
    height: 500px;
}

.article-paragraph-container{
    font-size: 16px;
    line-height: 1.5;
    padding: 5px 5px;
}

.paragraph-bigSpace{
    padding: 20px 5px;
}

@media (max-width: 1050px) {

}

@media (max-width: 930px) {
    .article-card-container{
        display: flex;
        flex-direction: column;
        padding: 30px;
    }

    .article-image img{
        width: 100vw;
    }
}