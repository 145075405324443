.info-solar-power-billing-container {
    min-height: 100vh;
}




/* InfoInstallationPriceCalculator.css */

.info-solar-power-billing-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

/*h1 {*/
/*    font-size: 24px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*h2 {*/
/*    font-size: 20px;*/
/*    margin-top: 20px;*/
/*    margin-bottom: 10px;*/
/*}*/

/*p {*/
/*    font-size: 16px;*/
/*    line-height: 1.5;*/
/*}*/

/*ol {*/
/*    list-style: decimal;*/
/*    margin-left: 20px;*/
/*}*/

/*li {*/
/*    margin: 10px 0;*/
/*}*/