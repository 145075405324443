/* ProfileRequests.css */

/* Container for the entire component */
.profile-requests-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    min-height: 100vh;
}

.profile-requests-box{
    margin: 20px 0;
}

/* Title styling */
.profile-requests-title {
    font-size: 24px;
    margin-bottom: -15px;
    color: #444444;
    text-align: center;

}

.profile-requests-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
    color: #444444;
    text-align: center;

}

/* Clickable header styling */
.profile-requests-container h4 {
    cursor: pointer;
    font-size: 18px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    box-shadow: 1px 1px 3px gray;
    color: #333333;

}

/* Table styling */
.price-indication-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

/* Table header row styling */
.price-indication-table th {
    background-color: #333333;
    color: #ffffff;
    text-align: left;
    padding: 8px;
}

/* Table data cell styling */
.price-indication-table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

/* Bold text in the table */
.price-indication-table td.bold {
    font-weight: bold;
}

/* Highlight for even rows */
.price-indication-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}



.admin-request-item-title{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.admin-request-item-p {
    background-color: #333333;
    color: #ff8000;
    font-weight: bold;
    margin: 0;
    padding: 5px;
    width: 35%;
}
