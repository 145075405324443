/* RequestsAdmin.css */

.admin-requests-container {
}

.admin-requests-title {
    text-align: center;
}

.admin-requests-ul {
    list-style-type: none;
}

.admin-request-item {
    background-color: #fff;
}

.admin-request-id,
.admin-request-flat-roof,
.admin-request-slanted-roof,
.admin-request-solar-panel,
.admin-request-total-cost {
    margin: 0;
}

.admin-request-bar-title-alignment {
    display: flex; /* Make it a flex container */
    flex-direction: row;
    justify-content: space-evenly;

}