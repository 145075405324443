.list-product-quick-view {
    border-radius: 10px; /* Add border radius to round the corners */
}
.product-quick-view {
    display: flex;
    flex-direction: column;
    border-radius: 10px; /* Add border radius to round the corners */
    margin-top: 25px;

}
.product-quick-view-align-column {
    display: flex;
    flex-direction: row;
}
.product-price {
    font-weight: normal;
}
.product-image {
    display: flex;
    justify-content: center;
    height: 400px;
    width: 50%;
}
.product-image img {
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
}

.product-features p {
    font-size: 1rem;
    margin: -5px 0 20px;
    text-align: start;
}

.list-item {
}
.product-title {
    text-align: start;
    margin-top: -3rem;
}
.product-unique-point {
    background-color: #444444;
    color: #ff6600;
    padding: 10px;
    text-align: start;
    width: 24%;
}
.product-best-pick {
    background-color: #444444;
    color: #ff6600;
    padding: 10px;
    text-align: start;
    width: 27%;
}

