.price-indication-card {
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none; /* Remove link underlining */
}

/*//TODO .price-indication-card-image max-width 85% for mobile responsive*/
.price-indication-card-image {
    max-width: 65%;
    border-radius: 50%;
}
.price-indication-card-label {
    background-color: #ff8000;
    color: #333333;
    padding: 5px;
    margin-top: -50px;
    font-size: 1.5rem;
    border-radius: 5px;
    z-index: 2;
    /*
    animation: flashing-price-indication 1.5s infinite alternate; !* Add flashing animation *!
    */
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}


.price-indication-card-text {
    background-color: #333333;
    color: #ffffff;
    padding: 70px 5px 10px 5px;
    font-size: 1.3rem;
    margin-top: -75px;
    border-radius: 10px;
    z-index: 1; /* Add this line to adjust the stacking order */
}


.price-indication-card:hover .price-indication-card-image {
    /* Apply hover styles for the image */
    /* For example, increase the image size */
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.price-indication-card:hover .price-indication-card-label {
    /* Apply hover styles for the label */
    /* For example, change the label color */
    background-color: #333;
    color: #ffffff;
    animation: paused;
}

.price-indication-card:hover .price-indication-card-text {
    /* Apply hover styles for the text */
    /* For example, change the text color */
    color: #ff8000;
    animation: bounce 0.5s infinite alternate;
}




@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}


@keyframes flashing-price-indication {
    from {
        background-color: #ff8000;
        color: #222;
    }
    to {
        background-color: #222;
        color: #FF5733;
    }
}