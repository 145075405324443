.IntroductionPriceIndication {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.IntroductionPriceIndication .intro-content-container {
    padding: 2rem;
    display: flex;
    flex-direction: row;
}


.intro-text {
    flex: 1;
    text-align: left;
    padding-right: 2rem;
}

.intro-pi-title {
    margin-bottom: 3rem;
}

.intro-card{
    max-width: 500px;
}


@media (max-width: 1200px) {
    .intro-card{
        max-width: 400px;
    }
}

@media (max-width: 1010px) {
    .intro-card{
        max-width: 300px;
    }
}

@media (max-width: 930px) {
    .IntroductionPriceIndication .intro-content-container {
        flex-direction: column;
    }

    .intro-card{
        max-width: 300px;
    }
}
