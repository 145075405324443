/* AppointmentsAdmin.css */

.admin-appointments-container {
    background-color: #f7f7f7;
}

.admin-appointments-title {
    text-align: center;
}

.admin-appointments-ul {
    list-style-type: none;
    padding: 0;
}

.admin-appointment-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
}

.admin-appointment-id,
.admin-appointment-email,
.admin-appointment-phone,
.admin-appointment-datetime {
    margin: 0;
}
/* Additional styles for BackToAdminPanel can be added here */
