/* PriceIndicationTable.css */

.price-indication-container {
    margin: 20px auto; /* Center horizontally */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f8f8;
    text-align: center; /* Center text content */

}

.price-indication-header {
    color: #4a4a4a;
    font-size: 24px;
    margin-bottom: 20px;
}

.price-indication-table {
    width: 80%; /* Adjust the width as needed */
    margin: 20px auto; /* Center horizontally */
    border-collapse: collapse;
}

.price-indication-table th,
.price-indication-table td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: left;
}

.price-indication-table th {
    background-color: #2c3e50;
    color: #ffffff;
}

.price-indication-table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

.price-indication-table tbody tr:hover {
    background-color: #e0e0e0;
}

/* PriceIndicationTable.js */

.price-indication-container {
    margin: 20px auto; /* Center horizontally */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f8f8;
    text-align: center; /* Center text content */
}

.price-indication-header {
    color: #4a4a4a;
    font-size: 24px;
    margin-bottom: 20px;
}

.price-indication-table {
    width: 80%; /* Adjust the width as needed */
    margin: 20px auto; /* Center horizontally */
    border-collapse: collapse;
}

.price-indication-table th,
.price-indication-table td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: left;
}

.price-indication-table th {
    background-color: #2c3e50;
    color: #ffffff;
}

.price-indication-table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

.price-indication-table tbody tr:hover {
    background-color: #e0e0e0;
}


