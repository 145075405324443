/* Overall container styles */
.installation-offer-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
}

/* Heading styles */
.installation-offer-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

/* Label styles */
.installation-offer-container label {
    display: block;
    margin-bottom: 5px;
}

/* Select styles */
.installation-offer-container select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

/* List styles */
.installation-offer-container ul {
    list-style-type: none;
    padding: 0;
}

/* List item styles */
.installation-offer-container li {
    margin-bottom: 5px;
}

/* Card styles */
.installation-company-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: white;

}

/* Company name styles */
.name-logo-description h2 {
    font-size: 20px;
    margin-bottom: 5px;
}

/* Company logo styles */
.name-logo-description img {
    max-width: 100px;
    margin-bottom: 5px;
}

/* Company description styles */
.name-logo-description p {
    font-size: 16px;
    color: #666;
}

.company-details {
    border-top: 1px solid #ccc;
    padding-top: 5px;


}

/* Company details styles */
.company-details p {
    margin-bottom: 5px;
}

/* Service area styles */
.active-service-area {
    border-top: 1px solid #ccc;
    padding-top: 5px;
    margin-top: 5px;
}

/* Service area heading styles */
.active-service-area h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

/* Socials styles */
.company-socials a {
    margin-right: 10px;
}

/* Social media icon styles */
.company-socials a img {
    width: 30px;
    height: 30px;
}
