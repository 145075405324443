/* ScheduleAppointment.css */

.schedule-appointment {
    text-align: center;
    min-height: 100vh;
    padding-top: 150px;
}

.schedule-appointment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.schedule-radio-group {
    display: flex;
    justify-content: center;
    margin: 0px 0;
}

.schedule-field-group {
    margin: 10px auto; /* Add space between fields and center horizontally */
    max-width: 80%;
}

.submit-schedule-appointment-button {
    background-color: #ff6600;
    color: #333;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, opacity 0.2s ease;
    /*
    animation: pulse 1.5s infinite; !* Apply the animation by default *!
    */
}

.submit-schedule-appointment-button:hover {
    background-color: #333;
    color: #ff6600;
    transform: scale(1.05);
    opacity: 0.9;
    animation: none; /* Remove animation on hover */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}


.backto-profile-button{
    background-color: #333333;
    color: white;
    font-size: 20px;
    margin-left: 20px;
    padding: 5px 10px;
}


