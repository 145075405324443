
body {
  font-family: 'Roboto', sans-serif;
}


html {
  overscroll-behavior: none !important;
  overflow-x: hidden;
}

/* Apply overscroll-behavior: none; only in Firefox */
@-moz-document url-prefix() {

  /* Apply the CSS property to the desired element */
  body {
    overscroll-behavior: none !important;
  }
}
