/* About.css */
.about-container {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: whitesmoke;

    min-height: 80vh;
    padding: 20px;
}

.about-box{
    display: flex;
    align-items: start;
    justify-content: center;
    width: auto;
    padding: 10px;
    border-radius: 30px;
}


.about-image{
    width: auto;
}
.about-image img{
    width: 25rem;
    background-color: #f5f5f5;
    margin-right: 1rem;
}


.about-text{
    background-color: #f8f8f8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow for a lifted effect */
    border-radius: 5px;
    width: auto;
    padding: 0 20px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}


.about-text h2 {
}

.about-text p {
    line-height: 1.5;
}

@media (max-width: 1100px) {

}


@media (max-width: 930px) {
    .about-box{
        display: block;
    }

    .about-image{
        display: none;
    }

    .about-text{
        width: 100%;
        padding: 0 10px;
    }
}
