
.cookie-consent-box{
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10000;
    background-color: #222222;
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.cookie-consent-box-aligned-start {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cookie-consent-box-aligned-start a {
    color: #ff6600;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.cookie-consent-box-aligned-end {
    flex: 1;
    display: flex;
    justify-content: space-around;
    margin: 1%;
}

.cookie-consent-box p {
    font-size: 1rem;
    color: #f5f5f5;
}

.cookie-consent-box button{
    font-size: 20px;
    height: fit-content;
    padding: 10px;
    cursor: pointer;
}


.cookie-consent-box-accept {
    background-color: #444;
    color: #ff6600;
    border: 2px solid #ff6600;
}
.cookie-consent-box-accept:hover {
    background-color: #333;
    color: #ff6600;
    border: 2px solid #ff6600;
}

.cookie-consent-box-deny {
    background-color: #444444;
    color: #222222;
    border: 2px solid;
}

.cookie-dont-show{
    display: none;
}

@media (max-width: 1200px) {
    .cookie-consent-box-aligned-start p{
        font-size: 2rem;
    }
}

@media (max-width: 1010px) {

}

@media (max-width: 930px) {

    .cookie-consent-box{
        flex-direction: column;
    }

    .cookie-consent-box-aligned-start p{
        font-size: 0.8rem;
    }

    .cookie-consent-box-aligned-start{
        padding: 1%;
    }

    .cookie-consent-box button{
        font-size: 0.5rem;
    }
}