
/* Demo Container */
.demo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5; /* Choose an appropriate background color */
    padding: 10px;
    gap: 10px;
}

/* Tool Container */
.tool-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 300px;
}

/* Tool Header */
.tool-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

/* Tool Picture */
.tool-picture {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}

/* Tool Title */
.tool-title {
    font-size: 1.4rem;
    font-weight: bold;
}

/* Tool Features List */
.tool-features {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #ff8000; /* Border with the color ff8000 */
    border-top: 2px solid #ff8000; /* Border with the color ff8000 */


}

/* Tool Description */
.tool-description p {
    font-size: 0.9rem;
    margin-bottom: 10px;
}

/* Tool Buttons Container */
.tool-buttons {
    display: flex;
    justify-content: space-between;
}

/* Primary "Start nu" Button */
.tool-primary-button {
    background-color: #ff8000;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tool-primary-button:hover {
    background-color: #ff8000;
}

/* Secondary "DEMO" Button */
.tool-secondary-button {
    background-color: transparent;
    color: #ff8000;
    border: 2px solid #ff8000;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.tool-secondary-button:hover {
    background-color: #ff8000;
    color: #fff;
}

/* Additional styling for active state (when buttons are clicked) */
.tool-primary-button:active, .tool-secondary-button:active {
    background-color: #ff8000;
}
