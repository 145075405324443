:root {
    --primary: #333;
}

.btn {
    padding: 8px 20px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    width: 100%; /* Set the button width to 50% for desktop */
    display: block;
}
@media screen and (max-width: 768px) {
    /* For mobile devices, reset the width to 100% */
    .btn {
        width: 100%;
    }
}
.btn--primary {
    background-color: transparent;
    color: #ff8000;
    border: 2px solid #ff8000;
    transition: background-color 0.3s, color 0.3s;
}
.btn--primary:hover {
    background-color: #ff8000;
    border: 2px solid #ff8000;
    color: #fff;
}
.btn--outline:hover {
    background-color: #ff6600; /* Darker shade on hover */
}
.btn--outline {
    background-color: #ff8000;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}


.btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
}