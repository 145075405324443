.login-container {
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    min-height: 100vh;
}

.login-section {
    background-color: whitesmoke;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    min-width: 350px;
}


.login-title {
    color: #333333;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 5%;
}

.login-description {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
}

.login-label {
    font-size: 16px;
    margin-bottom: 10px;
}

.login-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
}

.login-error {
    text-align: center;

    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.redirect-to-register {
    text-align: center;

}

.login-button {

    background-color: #444444;
    color: #ff8000;
    font-weight: bold;
    font-size: 18px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.forgot-password-link {
    text-align: center;

    font-size: 14px;
    margin-top: 10px;
    text-decoration: none;
    color: #007bff;
}

.forgot-password-link:hover {
    text-align: center;

    text-decoration: underline;
    color: #0056b3;
}


@media (max-width: 930px) {
    .login-title{
        margin-top: 20%;
    }
}