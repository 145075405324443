/* Request.css */

/* Overall container for the form */
.request-container {
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding and border in the width calculation */
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Header of the form */
.request-header {
    background-color: #444444;
    color: #f5f5f5;
    font-weight: bold;
    text-align: start; /* Center-align the header text */
    padding: 10px;
}

/* Form section */
.request-form {
}

.form-group-alignment {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
/* Individual form group */
.form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 0 10px;
}

/* Label for form inputs */
.form-label {
    color: #444444;
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    flex-basis: 100%;
    text-align: center;
}

/* Form input fields */
.form-input {
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
    outline: 1px solid #444; /* Set the custom outline color to #444 */
    text-align: center;
}



/* Submit button */
.submit-button {
    background-color: #444;
    color: #f5f5f5;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: block; /* Make the button a block element for width alignment */
    margin-top: 2rem;
    /*
    animation: moveButton 1s ease-in-out alternate infinite;
    */
}
.submit-button:hover {
    background-color: #333;
    color: #ff6600;
}

.flash {
    background-color: #333;
    color: #ff6600;}


.request-submit-button-container{
    width: 100%;
    display: flex;
    justify-content: center;
}




.form-error {
    color: red;
    text-align: center;
}



/*
@keyframes moveButton {
    from {
        transform: translateX(0); !* Initial position *!
    }
    to {
        transform: translateX(10px); !* Move 10px to the right *!
    }
}
*/
