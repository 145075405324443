/* solarPanelCarousel.css */

/* Container for the entire component */
.view-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.solar-panel-carousel {
    text-align: center;
}

/* Heading style */
.solar-panel-carousel h2 {
    color: #333;
}

/* Styles for the Slider component */
.slick-slider {
    width: 80%;
    margin: 0 auto;
    position: relative; /* Ensure the slider is a positioned container */
}

/* Styles for individual slides */
.slick-slide {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Image styling */
.slick-slide img {
    max-width: 100%;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: block;
    margin: 0 auto;
    object-fit: cover; /* Adjust based on your layout needs */
}

/* Title styling */
.slick-slide h3 {
    color: #333;
    display: block;
    margin: 10px auto; /* Adjust vertical margin as needed */
    text-align: center; /* Center the text horizontally */
}

/* Additional information styling */
.slick-slide p {
    color: #666;
    display: block;
    margin: 5px auto; /* Adjust vertical margin as needed */
    text-align: center; /* Center the text horizontally */

}

.slick-slide li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Price styling */
.slick-slide p:last-child {
    font-weight: bold;
    color: #e44d26; /* You can replace this color with your desired color */
}

/* Styling for navigation buttons */
.slick-next::before,
.slick-prev::before {
    font-size: 20px;
    color: #e44d26; /* You can replace this color with your desired color */
    background-color: #fff;
    border: 2px solid #e44d26; /* You can replace this color with your desired color */
    border-radius: 50%;
    padding: 10px;
    z-index: 1; /* Adjust z-index to make sure the buttons are on top of the slides */
    position: absolute; /* Position buttons absolutely within the container */
    top: 50%; /* Position buttons at the vertical center of the container */
    transform: translateY(-50%); /* Adjust for the vertical center */
}

.slick-prev::before {
    z-index: 2; /* Increase z-index for the 'Previous' button to appear above the 'Next' button */
    left: -12px; /* Position the 'Previous' button to the left */
}

.slick-next::before {
    right: 10px; /* Position the 'Next' button to the right */
}

.slick-prev::before:hover,
.slick-next::before:hover {
    color: #fff;
    background-color: #e44d26; /* You can replace this color with your desired color */
}

/* Add a row to the slick-track for centering */
.slick-track {
    display: flex;
    justify-content: center;
}

.slick-current {
    background-color: #e6e6e6; /* Example background color for highlighting */
}

/* Styling for slick-carousel dots */
.slick-dots {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 15px 0; /* Adjust the margin based on your design preferences */
    top: 100%;
}

.slick-dots li {
    margin: 0 5px; /* Adjust the spacing between dots */
}

.slick-dots li button {
    position: relative;
    padding: 0;
}

.slick-dots li button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px; /* Adjust the width and height of the dots */
    height: 12px;
    border: 2px solid #333; /* Dot border color when not active */
    background-color: transparent;
    border-radius: 50%;
}

.slick-dots li.slick-active button::before {
    background-color: #e44d26; /* Dot color when active */
    border-color: #e44d26; /* Dot border color when active */
}


.sp-c-button {
    padding-top: 50px;
}