/* PriceIndicationAdmin.css */

.admin-price-indication-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
    margin-top: 0px;
    margin-bottom: 80px;
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Allow items to wrap when the screen size is small */
}

.admin-price-indication-section {
    flex: 1; /* Equal width for both sections */
    padding: 10px;
}

.admin-price-indication-update {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
}

.admin-price-indication-values {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
}
/* Rest of your CSS remains unchanged */
/* Stijl voor de titel */
.admin-price-indication-header {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
}

/* Stijl voor de lijst */
.admin-price-indication-list {
    list-style-type: none;
    padding: 0;
}

/* Stijl voor de labels */
.admin-price-indication-label {
    margin: 0;
    font-weight: normal; /* Vetgedrukte labels */
}

/* Stijl voor de waarden */
.admin-price-indication-value {
    margin: 0;
    margin-bottom: 18px; /* Voeg wat ruimte toe tussen label en waarde */
}


.admin-price-indication-input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.admin-price-indication-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}

.admin-price-indication-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.back-to-admin-panel-button-container {
    align-self: center; /* Center button horizontally */
    margin-top: 20px;
}