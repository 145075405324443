/* Container styles */
.delete-bp-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;


}

/* Heading styles */
.delete-bp-container h1 {
    font-size: 24px;
    margin-bottom: 20px;

}

/* List styles */
.delete-bp-container ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

/* Company item styles */
.delete-bp-container li {
    margin-bottom: 30px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
}

/* Company name styles */
.delete-bp-container h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

/* Description styles */
.delete-bp-container p {
    margin: 0;
}

/* Strong styles */
.delete-bp-container strong {
    font-weight: bold;
    margin-right: 5px;
}