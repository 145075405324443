/* Solarpanel.css */
.solarpanel-container {
    width: 100%;
}

.panel-navigation {
    background-color: whitesmoke;
    margin: 20px 10px;
}

.product-image{
    display: flex;
    align-items: center;
}

.product-image img{
    width: 15rem;
    height: 25rem;
}

.panel-list {
    width: 100%;
}


.panel-button {
    background-color: #444;
    color: #f5f5f5;
    margin-left: 35rem;
    margin-bottom: -5rem;
    padding: 15px 10px;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px;
}

.panel-button.prev {
    width: 14%;
    margin-right: 3px;
}

.panel-button.next {
    width: 14%;
    margin-left: 3px;
}

.panel-button:hover {
    background-color: #444444;
    color: #ff6600;
}


.product-details {
}



.panel-list {
}

.list-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.list-item h2.panel-brand {
    margin-top: 0px;
}

.list-item h3.panel-name {
    margin-top: -25px;
    margin-bottom: 0px;
}

.list-item h2.panel-brand,
.list-item h3.panel-name,
.list-item p.product-price {
    color: #444444;
}

.list-item.selected {
    background-color: #cccccc;
    padding: 10px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}


.list-item {
    display: inline-block; /* Display items horizontally */
    width: calc(25% - 10%); /* Each item takes 25% width minus margins */
    border-radius: 5px;
    margin-right: 15px; /* Use 5px for to disable overlapping */
    padding-top: 5px;
    cursor: pointer;
    box-sizing: border-box; /* Include padding and border in the width */

    background-color: #f5f5f5;
    color: #333333;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);


}

.carousel-status {
    display: none !important;
}

.carousel .dot-selected {
    display: none !important;
}

.carousel .control-dots {
    display: none !important;
}

.panel-list-item-image {
    width: 120px;
    height: 140px;
    border-radius: 5%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center horizontally using auto margins and set top margin to -15px */
}

.panel-list-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.sp-display-sizes {
}

.sp-display-size-mm-config {
    font-size: 11px;
    vertical-align: super;
    font-weight: 600;
}

.sp-display-size-x-config {
    font-size: 11px;
    font-weight: 600;
}


.product-size-mm {
    font-size: 12px;
    vertical-align: super;
    font-weight: normal;
    text-align: center;
}


.panel-name {
    font-weight: normal;
}

.solar-panel-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.solar-panel-grid > * {
    flex: 1; /* This makes the items take up equal space */
    max-width: 200px; /* Adjust the max-width as needed */
    margin: 10px; /* Adjust margin as needed for gap */
}


.solar-panel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0; /* Improved border color */
    padding: 10px;
    /* Add any additional styling you want for each panel item */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Small shadow */
    cursor: pointer;
}



.selected-item {
    background-color: #cccccc;
    padding: 10px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.panel-image {
    text-align: center; /* Center-align the text within the image container */
}

.panel-image img {
    max-width: 100%; /* Limit the image width to the container's width */
    max-height: 150px; /* Set the maximum height for the image (adjust as needed) */
    /* You can also use 'width' and 'height' properties to set exact dimensions if needed */
}

.panel-brand {
    margin-bottom: 5px; /* Adjust the margin to control the spacing between brand and name */
    text-align: center; /* Center-align the text within the panel item */
}

.panel-name {
    margin-top: 5px; /* Adjust the margin to control the spacing between brand and name */
    text-align: center; /* Center-align the text within the panel item */
}

.product-unique-point, .product-best-pick{
    width: fit-content;
    padding: 10px 20px;
}


@media (max-width: 1200px) {
    .logo-buffer{
        width: 15%;
    }
}

@media (max-width: 1010px) {
    .logo-buffer{
        width: 2%;
    }
}

@media (max-width: 930px) {
    .solar-panel-grid{
        display: flex;
        flex-wrap: wrap;
        gap: 1px;
        justify-content: center;
        font-size: 15px;
    }

    .product-image {
        display: none;
    }

    .product-title{
    }

    .product-quick-view-align-column{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }


    .product-features {
        border-top: 5px solid #444444;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        padding: 20px;
    }


}