.company-advertisement {
    display: flex;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    margin-bottom: 5px;
}

.company-logo img {
    width: 100px; /* Adjust the width of the company logo */
    height: auto; /* Ensure the logo maintains its aspect ratio */
    margin-right: 20px;
}

.company-info {
    flex-grow: 1; /* Make the company info section expand to fill available space */
}

.company-info h3 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #333;
}

.company-info p {
    margin-bottom: 10px;
    color: #666;
}

.company-info a {
    display: inline-block;
    padding: 8px 20px;
    background-color: #ff8000;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.company-info a:hover {
    background-color: #ff6600;
}
