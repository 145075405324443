.toolkit-card-container {
    z-index: -1000;
} /*//TODO this can be removed i think*/
.toolkit-card {
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none; /* Remove link underlining */
    width: 25%;
}
.toolkit-card-title {
    text-decoration: none;
    padding: 10px;
}

.toolkit-card-image {
    width: 70%;
    border-radius: 50%;
}

.toolkit-card-label {
    background-color: #ff8000;
    color: #333333;
    padding: 5px;
    margin-top: -50px;
    font-size: 1.5rem;
    border-radius: 5px;
    z-index: 2;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
.toolkit-card-text {
    background-color: #333333;
    color: #ffffff;
    padding: 70px 5px 10px 5px;
    font-size: 1.3rem;
    margin-top: -75px;
    border-radius: 10px;
    z-index: 1; /* Add this line to adjust the stacking order */
}


.toolkit-card:hover .toolkit-card-image {
    /* Apply hover styles for the image */
    /* For example, increase the image size */
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

.toolkit-card:hover .toolkit-card-label {
    /* Apply hover styles for the label */
    /* For example, change the label color */
    background-color: #333333;
    color: #ffffff;
}

.toolkit-card:hover .toolkit-card-text {
    /* Apply hover styles for the text */
    /* For example, change the text color */
    color: #ff8000;
    animation: bounce 0.5s infinite alternate;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}


@media (max-width: 930px) {
    .toolkit-card{
        width: 90%;
    }
}