/* UpdateBp.css */

.update-bp-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
}


input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button.update-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button.update-button:hover {
    background-color: #0056b3;
}
.return-button {
    background-color: #3498db; /* Blauwe kleur, je kunt dit aanpassen aan je ontwerp */
    color: #fff; /* Witte tekst voor contrast */
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.return-button:hover {
    background-color: #2980b9; /* Donkerdere tint van blauw bij hover */
}

/* Add more styles as needed */
