/* ProfileAppointments.css */

/* Container for the entire component */
.profile-appointments-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;


}

/* Title styling */
.profile-appointments-title {
    font-size: 24px;
    margin-bottom: 40px;
    color: #444444;
    text-align: center;
}

/* List styling */
.profile-appointments-ul {
    list-style: none;
    padding: 0;
}



/* List item styling */
.profile-appointments-li {
    background-color: #444444;
    border: 1px solid black;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
}

.profile-appointments-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; /* Add this line for even spacing */
    margin-bottom: 5px;
    color: #f5f5f5;

}

/* Info title styling */
.profile-appointments-info-title {
    font-weight: bold;
    padding: 5px;
    color: #ff6600;
    background-color: #333333;
    text-align: center;
}

/* Dynamical info styling */
.profile-appointments-info-dynamical {
    text-align: center;
    background-color: #333333;
    color: #f5f5f5;
}

/* Additional paragraphs styling */
.profile-appointments-p {
    display: flex;
    justify-content: center;
    color: #f5f5f5;
    background-color: #333333;
    text-align: center;

}

.profile-appointments-p-dynamical {
    color: #ff6600;
    background-color: #333333;
    text-align: center;

}

/* Link styling */
.profile-appointments-p a {
    color: #ff6600;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
    background-color: #333333;
    text-align: center;

}


.profile-appointments-p a:hover {
    text-decoration: underline;
    color: darkorange;
}


.contact-paragraph a {

}

.contact-paragraph a:hover {
}

.appointment-button-container{
    display: inline-flex;
    flex-direction: column;
    margin-top: 15px;
    width: auto;
}

.appointment-button-container button{
    cursor: pointer;
    padding: 5px;
    border-radius: 50px;
}
