.welcome-container {
    min-height: 100vh;
}


/* Welcome.css */

.welcome-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.welcome-title  {
    margin-bottom: 20px;
}
.welcome-title a {
    color: #ff6600;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.welcome-title a:hover {
    text-decoration: underline;
    color: darkorange;
}

.welcome-p {
    line-height: 1.5;
    margin: 10px 0;
}

p a{
    color: #ff6600;
}
/* account-benefits.css */

/* account-benefits.css */

.account-benefits {
    background-color: #f8f8f8; /* Lighter background color for a cleaner look */
    padding: 30px; /* Increased padding for more spacing within the benefits section */
    border-radius: 12px; /* Larger border-radius for a softer and more modern appearance */
    margin-top: 30px; /* Increased margin for better separation from the content above */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow for a lifted effect */
    transition: background-color 0.3s ease-in-out; /* Smooth transition for background color change */
}

.account-benefits:hover {
    background-color: #ececec; /* Lighter background color on hover for interactive feedback */
}

.account-benefits p {
    font-size: 18px; /* Larger font size for better readability */
    margin-bottom: 15px; /* Increased margin for better spacing */
    color: #333; /* Darker text color for better contrast */
}

.account-benefits ul {
    list-style: none;
    padding: 0;
}

.account-benefits li {
    display: flex;
    align-items: center;
    margin-bottom: 12px; /* Increased margin between list items for better separation */
}

.account-benefits li svg {
    margin-right: 10px; /* Increased margin between icon and text for better alignment */
    font-size: 20px; /* Larger icon size for better visibility */
    color: #4CAF50; /* Green color for consistency with the existing icon style */
}
.highlighted-text {
    color: #ff6600;
    /* Add any additional styles as needed */
}
/* Add any additional styles as needed */

