.admin-combi-dashboard-container {
    min-height: 100vh;
}

.admin-combi-dashboard-flex-container {
}


/* Your CSS file */

.admin-combi-dashboard-body-container {
    display: flex; /* Make it a flex container */
    align-items: flex-start; /* Align items to the top of the container */
    justify-content: space-between; /* Space elements evenly */
}

.admin-combi-dashboard-body-start-container,
.admin-combi-dashboard-body-end-container {
    flex: 1; /* Both elements share equal width */
    padding: 10px; /* Adjust padding as needed */
}

/* Add any other styling you need for the individual containers */


.search-container {
    display: flex; /* Make it a flex container */
    align-items: center; /* Align items to the top of the container */
    justify-content: space-between; /* Space elements evenly */
    padding: 10px;
}