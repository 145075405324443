/* CreateBusinessProfile.css */

.installation-c-form-container {
    max-width: 600px;
    margin: 20px auto; /* Increased margin for better spacing */
    padding: 20px; /* Added padding for better spacing */
    border: 1px solid #ccc; /* Added border for a container effect */
    border-radius: 8px; /* Rounded corners for the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Added box shadow for a subtle lift */
}

.installation-c-form-container h2 {
    color: #333;
    text-align: center; /* Centered heading text */
}
.installation-c-form-container h3 {
    text-align: center; /* Centered heading text */
}

.installation-c-form-container h4 {
    color: #333;
    text-align: center; /* Centered heading text */
}

.installation-c-form-container form {
    display: flex;
    flex-direction: column;
}

.installation-c-form-container label {
    color: #555;
    text-align: left;
}


.installation-c-form-container input,
.installation-c-form-container textarea,
.installation-c-form-container select {
    width: 100%;
    padding: 10px; /* Adjusted padding for input elements */
    margin-top: 5px;
    margin-bottom: 15px; /* Increased margin for better spacing */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
}

.installation-c-form-container button {
    background-color: #ff6600;
    color: white;
    padding: 12px 20px; /* Adjusted padding for button */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.installation-c-form-container button:hover {
    background-color: #ff6600;
}




.active-area-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjust the alignment as needed */
}

.align-row {
    flex: 0 0 calc(33.33% - 20px); /* 33.33% width for each row with 20px spacing */
    margin: 10px; /* Adjust the margin as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.active-area-container input[type="checkbox"] {
    margin-bottom: 5px;
}

.active-area-container span {
    display: inline-block;
    align-self: center;
    width: 100%;
    padding-bottom: 10px;
    text-align: center; /* Center the text within the span */
}







