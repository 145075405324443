.register-request-container {
    min-height: 100vh;
}

.register-request-container {
    text-align: center;
    padding: 20px;
}

.register-request-container h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.register-request-container p {
    font-size: 16px;
    color: #666;
    margin: 10px 0;
}


.register-request-container a:hover {
    text-decoration: underline;
}
