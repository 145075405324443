.IntroductionTheNextStep {
    display: flex;

    flex-direction: column; /* Change the direction to stack elements vertically */
    justify-content: center;
    align-items: center;
}

.intro-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    padding: 2rem;
    margin: auto;
    background-color: #f5f5f5;
    /*
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    */
}

.intro-text {
    flex: 1;
    text-align: left;
    padding-right: 2rem;
}

.intro-tns-title {
    margin-bottom: 3rem;
}

@media (max-width: 1200px) {
    .intro-card{
        max-width: 400px;
    }
}

@media (max-width: 1010px) {
    .intro-card{
        max-width: 300px;
    }
}

@media (max-width: 930px) {
    .intro-content-container{
        flex-direction: column-reverse;
    }

    .intro-card{
        max-width: 300px;
    }
}


