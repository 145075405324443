/* SolarpanelAdmin.css */

.sp-admin-container {
    display: flex;
    width: 100%;
    margin: 0 5%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
    flex-wrap: wrap; /* Allow items to wrap to the next row if needed */
}



/* Rest of your CSS remains unchanged */


.sp-admin-header {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center; /* Center the header */
}

.sp-admin-panel-list {
    list-style-type: none;
    padding: 0;
}

.sp-admin-panel-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
}

.sp-admin-panel-item:hover{
    border: solid 3px black;
}


.sp-admin-item-content {
    display: flex;
    flex-direction: row; /* Align children in a column */
    align-items: center; /* Center horizontally */
}


.sp-admin-image-container {
    text-align: center;
    width: 100%; /* Expand to full width */
}

.sp-admin-panel-item-paragraph {
    text-align: start;
    margin: 0;
}

.sp-admin-panel-item-paragraph-enable{
    align-self: end;
    padding: 5px 15px;
}

.sp-admin-assigned-image {
    max-width: 100%;
    max-height: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.sp-admin-crud-title {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center; /* Center the title */
}

.sp-admin-input-field-cn {
    margin-bottom: 13px;
    width: 100%; /* Expand input fields to full width */
}

.sp-admin-crud-buttons {
    display: flex;
    justify-content: center;
    margin: 40px 10px;
}


.sp-admin-crud-add-button {
    padding: 10px 20px;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
.sp-admin-crud-buttons button {
    margin-right: 10px; /* Adjust this to add spacing between the buttons */
}

.sp-admin-crud-add-button:hover {
    background-color: #0056b3;
}

.sp-admin-crud-put-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

}

.sp-admin-crud-put-button:hover {
    background-color: #0056b3;
}

.sp-admin-crud-del-button {
    padding: 10px 20px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

}

.sp-admin-crud-del-button:hover {
    background-color: #b02a37;
}

.sp-admin-crud-operations {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    border: solid 1px black;
    border-radius: 30px;
    padding: 20px;

}


.sp-admin-aligned-right {
    width: auto;
    padding: 0 20px;
}
.sp-admin-aligned-left {
    width: 40%;
}

.sp-admin-panel-text {
    display: grid;
    grid-template-columns: 1fr 1fr;

    text-align: center;
    width: 100%; /* Expand to full width */
}

.sp-admin-panel-text-alignment {
}

.sp-admin-image-alignment{
}

.selected-panel{
    border: solid 3px black;
}
