/* Add this to your ProfileAppointments.css file */
.new-request-button {
    background-color: #444444;
    color: #ff6600;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
    border-radius: 5px;
    font-weight: bold;
    margin-right: 5px;
}

