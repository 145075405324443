/* PriceQuote.css */

/* PriceQuote.css */

/* PriceQuote.css */

/* Container styling */
.price-quote-container {
    position: relative; /* Set container as the positioning context */
    max-width: 600px;
    margin: auto;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center; /* Center the content horizontally */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content vertically */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
}

/* Logo styling */
.price-quote-logo {
    position: absolute;
    top: 0px; /* Adjust the top position to move it off-screen a bit */
    right: 10px; /* Adjust the left position to move it more to the right */
    max-width: 80px;
    height: 80px;
}



/* Heading styling */
.price-quote-header {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

/* Form styling */
.price-quote-form {
    display: grid; /* Use grid layout */
    gap: 15px;
    width: 100%; /* Ensure the form takes full width within the container */
}

.price-quote-label {
    color: #555;
    text-align: left;
}

.price-quote-input {
    width: 100%; /* Ensure all input fields have the same width */
    padding: 12px; /* Increase padding for better consistency */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in the total width */
}

.price-quote-checkbox {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left; /* Align checkbox label to the left */
}

.price-quote-submit {
    background-color: #ff6600; /* Use provided background color */
    color: #ffffff; /* Use provided text color */
    border: none;
    padding: 10px 20px; /* Use provided padding */
    border-radius: 4px;
    font-size: 16px; /* Use provided font size */
    cursor: pointer;
    margin-right: 10px; /* Use provided right margin */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Use provided text shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.price-quote-submit:hover {
    background-color: #cc5500; /* Darker orange on hover */
}
