.information-page-container {
    min-height: 100vh;
}

/* Information Page Container */
.information-page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

/* Page Header */
.article-page-header {
    text-align: center;
}

.article-page-header h1 {
}

/* Article List */
.article-list {
    margin-top: 20px;
}

/* Individual Articles */
article {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    background-color: #fff;
}

article h2 {
    margin: 0;
    padding: 5px;
}

article p {
    color: #555;
}

article a {
    color: #ff8000;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

article a:hover {
    text-decoration: underline;
    color: darkorange;
}

.article-card-container img {
    width: 150px;
    height: auto;
}