/* homepage.css */

.homepage-container {
    color: #333;
    background-color: #f5f5f5;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
}


.home-below-hero-container{
    padding: 0 5% 0 5%;
    margin-top: 5%;
}

.home-below-hero-container div{
    margin-top: 2rem;
    margin-bottom: 2rem;
}


.homepage-heading {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #333;
}

.homepage-paragraph {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 15px;
    color: #333;
}

.homepage-subheading {
    font-size: 1.4rem;
    margin: 20px 0 10px;
    color: #333;
}

.homepage-list {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 15px;
    color: #333;
}

.homepage-list li {
    margin-bottom: 5px;
}

.contact-button {
    margin-top: 20px; /* Add some spacing between the content and the button */
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    background-color: #ff8000;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.contact-button:hover {
    background-color: #333;
    color: #ff8000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



@media (max-width: 1200px) {
    .intro-card{
        max-width: 400px;
    }
}

@media (max-width: 1010px) {
    .intro-card{
        max-width: 300px;
    }
}

@media (max-width: 930px) {
    .home-below-hero-container{
        padding: 0 2%;
    }
}
