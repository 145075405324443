/* RegisterPage.css */
.register-page-container {
    background-color: #f0f0f0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    min-height: 100vh;
}



.register-page-login {
    color: #ff8000;
    background-color: #444444;
    padding: 10px;
    border: 2px solid black;
    cursor: pointer;
}

.register-page-login:hover {
    background-color: #333333;
}

@media (max-width: 930px) {
    .register-page-title{
    }
}
