.solarpanel-installation-price-container {
    min-height: 100vh;
}

/* Page Header */
.article-page-header {
    text-align: center;
}

.article-page-header h1 {
    font-size: 24px;
    color: #333;
}

article h2 {
    font-size: 20px;
    margin: 0;
    background-color: #444444;
    color: #ff6600;
    padding: 5px;
}
