/* Add this to your AdminPanel.css file */
.admin-panel-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #f0f0f0;
    border-radius: 5px;
    text-align: center;
    max-width: 50%;
    min-height: 100vh;
}






.admin-header {
    color: #333;
    margin-bottom: 5rem;
}

.admin-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admin-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #666666; /* Dark Gray */
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.admin-button:hover {
    background-color: #ff7700;
}
/* Your CSS file */

.admin-button-combi-dash {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #444444; /* Dark Gray */
    color: #ff8000;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
}
