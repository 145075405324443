/* UsersAdmin.css */

.users-admin {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-list-and-details-align-next-to-eachother {
    display: flex; /* Make it a flex container */
    align-items: center; /* Align items to the top of the container */
    justify-content: center; /* Align items horizontally to the start */
    width: 100%;
}

.user-list-container,
.user-details {
    flex: 1; /* Both elements share 50% of the available space */
    padding: 5px;
}

.user-item {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.user-item:hover {
    background-color: #f0f0f0;
}

.user-item.selected {
    background-color: #ff8000;
    color: #fff;
}

.user-details p {
    padding: 2px;
    margin: 0.4rem;
}

.user-details strong {
    font-weight: bold;
}

.user-list-item-bar {
    display: flex; /* Make it a flex container */
    flex-direction: row;
    justify-content: space-evenly;

}

.user-list-container {
    max-height: 15rem; /* Adjust the max height as needed */
    overflow-y: auto; /* Add a vertical scrollbar when content overflows */
}

.search-button{
    margin-left: 20px;
    padding: 9px 15px;
    font-size: 0.8rem;
}

.search-button:hover{
    background-color: green;
    color: white;
    cursor: pointer;
}

/* Aanvullende stijlen voor de zoekbalk */







