/* Add this to your ProfileAppointments.css file */
.profile-back-to-my-profile {
    background-color: #222222;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
    border-radius: 5px;
}

.profile-back-to-my-profile:hover {
    background-color: #ff8000;
    color: #222222;
}