/* PqApprovalPage.css */

.bp-successful-page-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 100vh;

    /* Center vertically and horizontally */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bp-successful-page-header {
    color: #4CAF50; /* Green color for success */
}

.bp-successful-page-message {
    font-size: 18px;
    margin-top: 10px;
    color: #333;
}

/* Add more styles or adjust as needed */
